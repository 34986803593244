import React, { useEffect } from 'react'
import Particles from 'react-particles-js'
const Connections = props => {
    return (
        <div className="absolute h-full w-full left-0">
            <Particles
                className="h-full"
                params={{
                    "particles": {
                        color: { value: "#2D3748" },
                        lineLinked: {
                            color: "#2D3748",
                        },
                        "number": {
                            "value": 50
                        },
                        "size": {
                            "value": 3
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "repulse"
                            }
                        }
                    }
                }} />
        </div>

    )
}

Connections.propTypes = {

}

export default Connections
