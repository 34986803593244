import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '../pages/index'
import Layout from '../components/layout/Layout';
import { Button } from '../components/elements';
import Connections from '../js/Connections'
import SEO from "@layout/SEO"
import { navigate } from "gatsby"


const Contact = props => {


    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }


const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        if (!item.submit) return {
            ...obj,
           ...item
        }
        return {
            ...obj,
        }
    }, initialValue);
};


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = Object.values(e.target.elements).map(input => ({[input.name]: input.value}))
        const formData = convertArrayToObject(data)

        if (formData["bot-field"]) {
            return navigate("/thank-you")
        }


        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "Contact", ...formData })
        })
            .then(() => {
                return navigate("/thank-you")
            })
            .catch(error => console.log(error))
    }

    return (
        <>
        <SEO title="Contact us" description="Got a project? Need some help? Not quite sure what you need but you know there is something? Lets talk. Invest 30 mins in exploring the possibilities." />
        <Layout>
            <Section fullHeight={false} fullWidth={true} className="flex-col " >

                <div className="w-full py-40 pb-56 md:pb-64 flex flex-col justify-center px-2 sm:px-8 ">
                    <div style={{
                        transform: 'rotate(3deg)', background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
                        backgroundSize: '400% 400%',
                        animation: 'gradient 10s ease infinite'
                    }} className="bg-primary py-8 px-4 inline-block max-w-5xl mx-auto self-center relative z-40 ">
                        <h1 style={{ transform: 'rotate(-3deg)' }} className="text-center self-center w-full text-gray-100 text-2xl md:text-4xl lg:text-5xl px-2 md:px-12 ">Lets get the ball rolling, <br /> start today, start now</h1>
                    </div>
                    <Connections />
                </div>

            </Section>

            <div className="max-w-5xl px-2  -mt-32 mx-auto">


                <div className="max-w-5xl mx-auto shadow-xl bg-white  mb-24 md:mb-56 rounded-lg p-2 relative">
                    <div className="relative bg-white mx-2">
                        <div className="absolute inset-0">
                            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
                        </div>
                        <div className="relative mx-auto lg:grid lg:grid-cols-5 rounded-lg">
                            <div className="bg-gray-50 py-8 md:py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                                <div className="max-w-lg mx-auto">
                                    <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                                        Get in touch
                                </h2>
                                    <p className="mt-3 text-lg leading-loose text-gray-500">
                                        Whether you want to get the ball rolling on a new project (of any scale) or just find out a little more, reach out with the form or email below. <b>Why not invest 30 minutes of your time exploring whether we'd be a good fit to work together?</b>
                                </p>
                                    <p className="mt-3 text-sm leading-loose text-gray-500">
                                        We are based in London and typically respond within 1 business day.
                                    </p>
                                    <dl className="mt-8 text-base leading-6 text-gray-500">
                                        <div className="mt-3">
                                            <dt className="sr-only">Email</dt>
                                            <dd className="flex">
                                                <svg className="flex-shrink-0 h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                                </svg>
                                                <a href="mailto:info@antler.digital" className="ml-3">
                                                    info@antler.digital
                                            </a>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div className="bg-white pb-16 md:py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                                <div className="max-w-lg mx-auto lg:max-w-none">
                                    <form  
                                        onSubmit={e => handleSubmit(e)}
                                        data-netlify="true" 
                                        name="Contact" 
                                        action="/thank-you" 
                                        method="POST" 
                                        className="grid grid-cols-1 row-gap-6" 
                                        netlify-honeypot="bot-field">
                                    <p className="hidden">
                                        <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                                    </p>
                                        <div>
                                            <label for="Name" className="sr-only">Full name</label>
                                            <div className="relative border-2 border-gray-200 rounded-md">
                                                <input required name="Name" id="name" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Full name" />
                                            </div>
                                        </div>
                                        <div>
                                            <label for="Email" className="sr-only">Email</label>
                                            <div className="relative border-2 border-gray-200 rounded-md">
                                                <input name="Email" id="email" type="email" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div>
                                            <label for="Phone" className="sr-only">Phone</label>
                                            <div className="relative border-2 border-gray-200 rounded-md">
                                                <input name="Phone" id="phone" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Phone (include area / country code i.e. +44" />
                                            </div>
                                        </div>
                                        <div>
                                            <label for="Message" className="sr-only">Message</label>
                                            <div className="relative border-2 border-gray-200 rounded-md">
                                                <textarea required name="Message" id="message" rows="4" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        <div className="">
                                            <span className="inline-flex ">
                                                <Button name="submit" value="submit" type="submit" className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6  text-white bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo  transition duration-150 ease-in-out">
                                                    Submit
                                                </Button>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        </>
    )
}

Contact.propTypes = {

}

export default Contact
